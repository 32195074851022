import React, { useMemo, useState } from 'react';
import { FaArrowLeft, FaArrowRight, FaFilter } from 'react-icons/fa';
import { useTable, useGlobalFilter, usePagination } from 'react-table';

// Table component
const Table = ({ columns, data }) => {
  // State to manage hidden columns
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.reduce((acc, col) => ({ ...acc, [col.accessor]: !col.isInitiallyVisible }), {})
  );

  // State to manage dropdown visibility
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Toggle column visibility
  const handleColumnToggle = (e) => {
    const columnAccessor = e.target.value;
    setHiddenColumns(prevHiddenColumns => ({
      ...prevHiddenColumns,
      [columnAccessor]: !prevHiddenColumns[columnAccessor],
    }));
  };

  // Determine visible columns based on hiddenColumns state
  const visibleColumns = useMemo(() =>
    columns.filter(column => !hiddenColumns[column.accessor]),
    [hiddenColumns, columns]
  );

  // Use react-table hooks
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { pageIndex, globalFilter },
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    pageOptions = [], // Default to empty array
    nextPage,
    previousPage,
    page, // Page data for pagination
  } = useTable(
    {
      columns: visibleColumns,
      data,
      initialState: { pageIndex: 0, pageSize: 50 }, // Set the page size to 20
    },
    useGlobalFilter, // Add useGlobalFilter hook here
    usePagination // Add usePagination hook here
  );

  return (
    <div className="flex mt-2 rounded-lg">
      {/* Table */}
      <div className="w-full bg-white p-2 rounded-lg shadow-md">
        <div className="flex justify-end mb-2 space-x-4">
          <input
            type="text"
            value={globalFilter || ''}
            onChange={e => setGlobalFilter(e.target.value || undefined)}
            placeholder="Search..."
            className="p-2 border border-gray-300 rounded"
          />
          <div className="relative">
            {/* Trigger button */}
            <button 
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="flex items-center bg-white border rounded-lg shadow-md p-2"
            >
              <FaFilter className="mr-2" />
              <span>Filter Columns</span>
            </button>
            {/* Dropdown menu */}
            {isDropdownOpen && (
              <div className="p-2 absolute bg-white w-full">
                {columns.map(column => (
                  <div key={column.accessor} className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      value={column.accessor}
                      checked={!hiddenColumns[column.accessor]}
                      onChange={handleColumnToggle}
                      className="mr-2"
                    />
                    <label className="text-sm">{column.Header}</label>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="w-full max-w-full h-[400px] overflow-auto table-container">
          <table {...getTableProps()} className="min-w-full border-collapse">
            <thead className="bg-gray-300 overflow-y-hidden">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()} className="border p-2 text-left">
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="bg-gray-100">
              {page.map(row => {
                prepareRow(row);
                console.log(row.original.PDays)
                const hasTotal = row.cells.some(cell => String(cell.value).includes('Total'));
                const hasLastBalance = row.cells.some(cell => String(cell.value).includes('Last Balance'));
                const hasOpeningBalance = row.cells.some(cell => String(cell.value).includes('Opening Balance'));
                const hasPDays = row.original.PDays
                // const hasCr = row.cells.some(cell => String(cell.value).includes('Cr'));
                
                let rowClass = '';
                 if (hasTotal) {
                  rowClass = 'bg-gray-200 text-black font-bold'; 
                 } else if (hasLastBalance) {
                   rowClass = 'bg-gray-200 text-black font-bold';
                  } else if (hasOpeningBalance) {
                   rowClass = 'bg-gray-200 text-black font-bold';
                  } else if (hasPDays) {
                      if (hasPDays > 180) {
                        rowClass = 'bg-red-400 text-black font-bold';
                      } else if (hasPDays > 90) {
                        rowClass = 'bg-yellow-400 text-black font-semibold';
                      }else if (hasPDays > 60) {
                        rowClass = 'bg-cyan-200 text-black ';
                      }
                  }

                return (
                  <tr {...row.getRowProps()} className={`border-t ${rowClass}`}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()} className="border p-2 text-sm">
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex justify-center items-center mt-2 bg-gray-300 p-2">
          <button onClick={() => previousPage()} disabled={!canPreviousPage} className="mr-4 cursor-pointer">
            <FaArrowLeft />
          </button>
          <span className="mx-2">
            Page {pageIndex + 1} of {pageOptions.length === 0 ? '1' : pageOptions.length}
          </span>
          <button onClick={() => nextPage()} disabled={!canNextPage} className="ml-4 cursor-pointer">
            <FaArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Table;

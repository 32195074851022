import React, { useState, useEffect } from 'react';
import Table from '../../components/Datatable/SearchFilterTable';
import Dropdown from '../../components/DynamicComponents/DynamicDropdown';
import DynamicButton from '../../components/DynamicComponents/DynamicButton';
import DynamicDatePicker from '../../components/DynamicComponents/DynamicDatePicker';
import InputDropdown from '../../components/DynamicComponents/DynamicInputDropdown';
import axios from 'axios';
import { BASE_URI } from '../../utils/BaseURL';
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import Loader from '../../utils/Loader';

const columns = [
  {
    Header: "S No",
    Cell: ({ row }) => (
      <div style={{ textAlign: "center" }}>
        {row.original.id === "openingBalance" || row.original.id === "lastBalance" ? "" : row.index + 1}
      </div>
    ),
    isInitiallyVisible: true
  },
  {
    Header: "No",
    accessor: "VouNo",
    Cell: ({ value }) => (
      <div style={{  textAlign: "center" }}>{value}</div>
    ),
    isInitiallyVisible: false
  },
  {
    Header: "Date",
    accessor: "VDate",
    Cell: ({ value }) => {
      const date = new Date(value);
      if (isNaN(date.getTime())) {
        return "";
      }
      const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${date.getFullYear()}`;
      return formattedDate;
    },
    isInitiallyVisible: true
  },
  {
    Header: "Particulars",
    accessor: "LName",
    Cell: ({ value }) => (
      <div style={{  textAlign: "left" }}>{value}</div>
    ),
    isInitiallyVisible: true
  },
  {
    Header: "Depot",
    accessor: "DpAbbr",
    Cell: ({ value }) => (
      <div style={{  textAlign: "center" }}>{value}</div>
    ),
    isInitiallyVisible: false
  },
  {
    Header: "Debit",
    accessor: "DrAmt",
    Cell: ({ value }) => (
      <div style={{  textAlign: "right" }}>
        {!isNaN(parseFloat(value)) ? parseFloat(value).toFixed(2) : ""}
      </div>
    ),
    isInitiallyVisible: true
  },
  {
    Header: "Credit",
    accessor: "CrAmt",
    Cell: ({ value }) => (
      <div style={{  textAlign: "right" }}>
        {!isNaN(parseFloat(value)) ? parseFloat(value).toFixed(2) : ""}
      </div>
    ),
    isInitiallyVisible: true
  },
  {
    Header: "Balance",
    accessor: "Balance",
    Cell: ({ value }) => (
      <div style={{  textAlign: "right" }}>
        {Math.abs(parseFloat(value)).toFixed(2)}
      </div>
    ),
    isInitiallyVisible: true
  },
  {
  Header: "",
  accessor: "extra",
  Cell: ({ row }) => {
    const { id, Balance } = row.original;

    // Do not display "Dr" or "Cr" for openingBalance or lastBalance rows
    if (id === "openingBalance" || id === "lastBalance") {
      return null; // or return an empty string "" if you prefer
    }

    const balance = parseFloat(Balance) || 0;
    return (
      <div style={{  textAlign: "center" }}>
        {balance >= 0 ? "Dr" : "Cr"}
      </div>
    );
  },
  isInitiallyVisible: true
},
  {
    Header: "Remark",
    accessor: "Remark1",
    Cell: ({ value }) => (
      <div style={{  textAlign: "left" }}>{value}</div>
    ),
    isInitiallyVisible: false
  }
];

const Ledger = () => {
  const [filter, setFilter] = useState('');
  const [selectedDate, setSelectedDate] = useState([new Date(), new Date()]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [resultWebPartyList, setResultWebPartyList] = useState([]); 
  const [LCode, setLCode] = useState("");

  // Basic Information For API's
  let targetRoute = "Ledger";
  let menuValue = JSON.parse(localStorage.getItem("Menu"));
  let Depot = JSON.parse(localStorage.getItem("Depo"));
  let selectedLocation = localStorage.getItem("selectedLocation");
  const navigate = useNavigate();

  const targetPage = menuValue.find(
    (item) => item.FormName === targetRoute
  );
  const FormKey = targetPage ? targetPage.FormKey : null;

  const token = Cookies.get("token"); 
  var LogId;
  var DPCode;
  var typeOfUser;

  Depot.forEach((depot) => {
    if (depot.DPName === selectedLocation) {
      DPCode = depot.DPCode; 
    }
  });
  const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
  LogId = itemFromLocalStorage[0].LogID;
  typeOfUser = itemFromLocalStorage[0].UserType;

  // Config for API's
  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };
  const BASE_URL = BASE_URI;

  const dropdownOptions1 = [
    'All',
  ];

  useEffect(() => {
      const fetchPartiesData = async () => {
        try {
        const requestBody = {
          LogID: LogId,
          FormKey: FormKey,
          Depo: DPCode,
          AgentName: "",
        };

        const response = await axios.post(
          `${BASE_URL}/parties`,
          requestBody,
          config
        );
      const resultWebPartyList = response.data.resultWebPartyList;
      setResultWebPartyList(resultWebPartyList); // Store the result
      const partyNamesData = resultWebPartyList.map((row) => row.LName);
      const uniquePartyNames = Array.from(new Set(partyNamesData));
      console.log(uniquePartyNames)
      setDropdownOptions(uniquePartyNames);
    } catch (error) {
      if(error.response.status === 401){
        alert('Your Token is expired Please Login Again!!!')
        navigate('/login')
      } else {
        alert('Something went wrong!!!')
      }
    }
  };

    fetchPartiesData();
  }, []);

const handleSubmit = async (e) => {
  e.preventDefault();
  setShowLoader(true);
  if (typeOfUser !== 3 && filter === '') {
    alert("Please Select any one Party.");
    setShowLoader(false);
    return;
  }
  
  if (!selectedDate || selectedDate.length < 2) {
    alert("Please select both start and end dates.");
    setShowLoader(false);
    return;
  }

  const [startDate, endDate] = selectedDate;

  // Validate Dates
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    alert("Invalid date selected.");
    setShowLoader(false);
    return;
  }

  const year = startDate.getFullYear();
  const month = String(startDate.getMonth() + 1).padStart(2, "0");
  const day = String(startDate.getDate()).padStart(2, "0");
  const formattedStartDate = `${year}${month}${day}`;

  const endDateYear = endDate.getFullYear();
  const endDateMonth = String(endDate.getMonth() + 1).padStart(2, "0");
  const endDateDay = String(endDate.getDate()).padStart(2, "0");
  const formattedEndDate = `${endDateYear}${endDateMonth}${endDateDay}`;

  try {
    const requestData = {
      LogID: LogId,
      Depo: DPCode,
      PartyCode: typeOfUser === 3 ? resultWebPartyList[0].LCode : LCode,
      StartDate: formattedStartDate,
      EndDate: formattedEndDate,
    };

    const response = await axios.post(
      `${BASE_URL}/ledger`,
      requestData,
      config
    );

    const balAmtResult = response.data.balAmtResult;
    const ledgerDataResult = response.data.ledgerDataResult.map(
      (row, index) => ({
        ...row,
        id: index + 1,
      })
    );

    // Calculate balance dynamically
    let runningBalance = parseFloat(balAmtResult[0]?.Balance) || 0;
    let totalDebit = 0;
    let totalCredit = 0;
    const updatedLedgerData = ledgerDataResult.map(row => {
      const debit = parseFloat(row.DrAmt) || 0;
      const credit = parseFloat(row.CrAmt) || 0;
      totalDebit += debit;
      totalCredit += credit;
      runningBalance += debit - credit;
      return {
        ...row,
        Balance: runningBalance.toFixed(2),
      };
    });

    // Add Opening Balance row
    const openingBalanceRow = {
      id: "openingBalance",
      VouNo: "",
      VDate: "",
      LName: "Opening Balance",
      DpAbbr: "",
      DrAmt: "",
      CrAmt: "",
      Balance: balAmtResult?.[0]?.BalAmt
      ? Math.abs(balAmtResult[0].BalAmt).toFixed(2)
        : "0.00",
      Remark1: "",
    };

    // Add Last Balance row with totals
    const lastBalanceRow = {
      id: "lastBalance",
      VouNo: "",
      VDate: "",
      LName: "Last Balance",
      DpAbbr: "",
      DrAmt: totalDebit.toFixed(2),
      CrAmt: totalCredit.toFixed(2),
      Balance: runningBalance.toFixed(2),
      Remark1: "",
    };

    // Update table data with opening and last balance rows
    const combinedData = [openingBalanceRow, ...updatedLedgerData, lastBalanceRow];

    // Check if ledgerDataResult is empty
    if (ledgerDataResult.length === 0) {
      setTableData([openingBalanceRow]); // Display only opening balance row if no data available
    } else {
      setTableData(combinedData);
    }

  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setShowLoader(false);
  }
};


  useEffect(() => {
    let timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

const handleFilterChange = (value) => {
  if (!value) {
    console.error("Selected value is undefined");
    return;
  }
  
  setFilter(value);
  const selectedItem = resultWebPartyList.find(
    (item) => item.LName === value
  );
  if (selectedItem) {
    setLCode(selectedItem.LCode);
  } else {
    console.log("Item not found in the list");
  }
  setTableData([]); // Clear table data
};

const handleDateChange = (dates) => {
  setSelectedDate(dates);
  setTableData([]); // Clear table data
};

  return (
    <div className="p-3 mx-auto bg-gray-50 rounded-lg shadow-md ">
      {showLoader && <Loader />}
      <div className="flex flex-col gap-4 mb-1">
        <h1 className="text-xl text-center font-bold ">{targetPage.FormName}</h1>
        <form onSubmit={handleSubmit} className="space-y-2">
          <div className="grid grid-cols-1 lg:grid-cols-5 gap-2 ">
            <InputDropdown
              options={dropdownOptions}
              value={filter}
              onChange={(value) => handleFilterChange(value)}
              label="Party Name"
              isDisabled={false}
            />
            <div className="grid grid-cols-2 gap-1 ">
                <DynamicDatePicker
                  selectedDate={selectedDate[0]}
                  onDateChange={(date) => handleDateChange([date, selectedDate[1]])}
                  maxDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                  DateLabel="Start Date"
                />
                <DynamicDatePicker
                  selectedDate={selectedDate[1]}
                  onDateChange={(date) => handleDateChange([selectedDate[0], date])}
                  maxDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                  DateLabel="End Date"
                />
            </div>

          </div>
          <div className='grid justify-end '>
            <DynamicButton variant="primary" label="Submit" />
          </div>
        </form>
      </div>
      <Table columns={columns} data={tableData} />
    </div>
  );
};

export default Ledger;

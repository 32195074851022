import React, { useState, useEffect } from 'react';
import Table from '../../components/Datatable/SearchFilterTable';
import Dropdown from '../../components/DynamicComponents/DynamicDropdown';
import DynamicButton from '../../components/DynamicComponents/DynamicButton';
import DynamicDatePicker from '../../components/DynamicComponents/DynamicDatePicker';
import DynamicInput from '../../components/DynamicComponents/DynamicInput';
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { BASE_URI } from '../../utils/BaseURL';
import Loader from '../../utils/Loader';

const ItemRegister = () => {
  const [itemName, setItemName] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [filter, setFilter] = useState('All');
  const [selectedDate, setSelectedDate] = useState(null);
  const [allItems, setAllItems] = useState([]);
  const [rows, setRows] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedItemGroupName, setSelectedItemGroupName] = useState('All'); // New state for selected item group name

  // Basic Information For API's
  let targetRoute = "Item Register";
  let menuValue = JSON.parse(localStorage.getItem("Menu"));
  let Depot = JSON.parse(localStorage.getItem("Depo"));
  let selectedLocation = localStorage.getItem("selectedLocation");
  const navigate = useNavigate();

  const targetPage = menuValue.find(
    (item) => item.FormName === targetRoute
  );
  const FormKey = targetPage ? targetPage.FormKey : null;
  const token = Cookies.get("token"); 
  var LogId;
  var DPCode;
  Depot.forEach((depot) => {
    if (depot.DPName === selectedLocation) {
      DPCode = depot.DPCode; 
    }
  });
  const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
  LogId = itemFromLocalStorage[0].LogID;

  // Config for API's
  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };
  const BASE_URL = BASE_URI;

  const columns = [
    {
      Header: "S No",
      accessor: "id",
      align: "left",
      headerAlign: "left",
      Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
    isInitiallyVisible: true
    },
    {
      Header: "Item name",
      accessor: "IName",
      Cell: ({ value }) => <div style={{  }}>{value}</div>,
    isInitiallyVisible: true

    },
    
    { Header: "Unit", accessor: "UName", 
    isInitiallyVisible: false

     },
    {
      Header: "Price",
      accessor: "Price",
      Cell: ({ value }) => <div style={{ textAlign: "right" }}>{parseFloat(value).toFixed(2)}</div>,
    isInitiallyVisible: true

    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500); // Set loader to false after 1500 milliseconds

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    fetchItemGroupData();
  }, []);

  const fetchItemGroupData = async () => {
    try {
      const requestBody = {
        LogID: LogId,
        Depo: DPCode,
      };
      const response = await axios.post(
        `${BASE_URL}/itemLists/get-items`,
        requestBody,
        config
      );
      const AllItems = response.data.AllItems;
      if (AllItems.length === 0) {
        alert("No Data Available");
      }
      setAllItems(AllItems); // Adjusted to set only the array of items
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status === 401) {
        alert('Your Token is expired Please Login Again!!!');
        navigate('/login');
      } else {
        alert('Something went wrong!!!');
      }
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!itemName.trim()) {
    alert('Item Name is required.');
    return; // Exit the function if validation fails
  }
    setShowLoader(true);

    // Handle date formatting
    const year = selectedDate ? selectedDate.getFullYear() : new Date().getFullYear();
    const month = selectedDate ? String(selectedDate.getMonth() + 1).padStart(2, "0") : String(new Date().getMonth() + 1).padStart(2, "0");
    const day = selectedDate ? String(selectedDate.getDate()).padStart(2, "0") : String(new Date().getDate()).padStart(2, "0");
    const formattedStartDate = `${year}${month}${day}`;

    try {
      const requestBody = {
        "LogID": LogId,
        "FormKey": FormKey,
        "Depo": DPCode,
        "PartyName": '',
        "AgentName": '',
        "ItemGroup": filter === "All" ? '' : selectedItemGroupName,
        "ItemName": itemName,
        "Date1": formattedStartDate,
        "Date2": formattedStartDate,
        "RptCode": 0
      };
      const response = await axios.post(
        `${BASE_URL}/web-reports`,
        requestBody,
        config
      );
      const data = response.data.results;
      if (data.length === 0) {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 1000);
      }
      const formattedRows = data.map((item, index) => ({
        id: index + 1,
        IGName: item.IGName,
        IName: item.IName,
        BalQty: item.BalQty,
        UName: item.UName,
        Price: item.Price,
      }));
      setRows(formattedRows);
    } catch (error) {
      console.error("Error:", error.message);
      if (error.response && error.response.status === 401) {
        alert('Your Token is expired Please Login Again!!!');
        navigate('/login');
      } else {
        alert('Something went wrong!!!');
      }
    } finally {
      setShowLoader(false);
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setSelectedItemGroupName(e.target.selectedOptions[0].text); 
    setRows([]); 
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setRows([]); // Clear table data when date changes
  };

  const items = allItems.map((item) => ({
    value: item.IGCode, // Value for dropdown
    label: item.IGname, // Display name
  }));
  items.unshift({ value: "All", label: "All" });

   const handleItemNameChange = (e) => {
    const inputValue = e.target.value;
    console.log('Item Name:', inputValue);
    setItemName(inputValue);
    setRows([]); // Clear table data when date changes

  };

  return (
    <div className="p-3 mx-auto bg-gray-50 rounded-lg shadow-md">
      {showLoader && <Loader />}

      <div className="flex flex-col gap-4 mb-1">
        <h1 className="text-xl text-center font-bold ">{targetPage.FormName}</h1>
        <form onSubmit={handleSearch} className="space-y-2">
          <div className="grid grid-cols-2 lg:grid-cols-5 space-x-2">
            <Dropdown
              options={items}
              value={filter}
              onChange={handleFilterChange} 
              label="Item Group"
            />
            <DynamicInput
              label="Item Name"
              placeholder="Enter Item Name"
              value={itemName}
              onChange={handleItemNameChange} 
            />
            
          </div>
          <div className='grid justify-end'>
            <DynamicButton variant="primary" label="Submit" />
          </div>
        </form>
      </div>
      <Table columns={columns} data={rows} />
    </div>
  );
};

export default ItemRegister;

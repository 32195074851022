import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import Login from './components/Auth/Login';
import Layout from './components/DashBoard/Layout';

const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token'));

  const handleLogin = () => {
    const token = localStorage.getItem('token')
    Cookies.set('token', token, { expires: 4 / 24 });
    setToken(token);
  };
  console.log(token)

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login handleLogin={handleLogin} />} />
        <Route path="/dashboard/*" element={token ? <Layout /> : <Navigate to="/login" />} />
        <Route path="/" element={token ? <Navigate to="/dashboard/*" /> : <Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;

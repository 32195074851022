import React, { useEffect, useState } from 'react';
import Loader from '../../utils/Loader';
import axios from 'axios';
import { BASE_URI } from '../../utils/BaseURL';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Table from '../../components/Datatable/SearchFilterTable';
import DynamicButton from '../../components/DynamicComponents/DynamicButton';
import Dropdown from '../../components/DynamicComponents/DynamicDropdown';

const BalancePurchaseOrderConsolidated = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [agentName, setAgentName] = useState('All');
  const [partyName, setPartyName] = useState('All');
  const [type, setType] = useState('Consolidated'); // Default type
  const [agentNames, setAgentNames] = useState([]);
  const [partyNames, setPartyNames] = useState([]);
  const [types, setTypes] = useState(["Consolidated", "PartyWise"]);
  const [showAlert, setShowAlert] = useState(false);

  // Basic Information For API's
  let targetRoute = 'Balance Purchase Order (Consolidated)';
  let menuValue = JSON.parse(localStorage.getItem("Menu"));
  let Depot = JSON.parse(localStorage.getItem("Depo"));
  let selectedLocation = localStorage.getItem("selectedLocation");
  const navigate = useNavigate();
  const targetPage = menuValue.find(
    (item) => item.FormName === targetRoute
  );
  const FormKey = targetPage ? targetPage.FormKey : null;
  const token = Cookies.get("token"); 
  let LogId;
  let DPCode;
  
  Depot.forEach((depot) => {
    if (depot.DPName === selectedLocation) {
      DPCode = depot.DPCode; 
    }
  });
  
  const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
  LogId = itemFromLocalStorage[0].LogID;

  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };
  const BASE_URL = BASE_URI;

  useEffect(() => {
    const fetchAgentsData = async () => {
      try {
        const requestBody = { LogID: LogId, FormKey: FormKey, Depo: DPCode };
        const response = await axios.post(
          `${BASE_URL}/agents`,
          requestBody,
          config
        );
        const agentNamesData = response.data.resultWebAgentList.map(
          (row) => row.AName
        );
        const uniqueAgentNames = Array.from(new Set(agentNamesData));
        setAgentNames(uniqueAgentNames);
      } catch (error) {
        console.error("Error fetching agents data:", error);
        if(error.response.status === 401){
          alert('Your Token is expired. Please login again!');
          navigate('/login');
        } else {
          alert('Something went wrong!');
        }
      }
    };

    fetchAgentsData();
  }, []);

  useEffect(() => {
    const fetchPartiesData = async () => {
      try {
        let requestBody = {
          LogID: LogId,
          FormKey: FormKey,
          Depo: DPCode,
          AgentName: agentName === 'All' ? '' : agentName,
        };
        const response = await axios.post(
          `${BASE_URL}/parties`,
          requestBody,
          config
        );
        const partyNamesData = response.data.resultWebPartyList.map(
          (row) => row.Lname
        );
        const uniquePartyNames = Array.from(new Set(partyNamesData));
        setPartyNames(uniquePartyNames);
      } catch (error) {
        console.error("Error fetching parties data:", error);
        if(error.response.status === 401){
          alert('Your Token is expired. Please login again!');
          navigate('/login');
        } else {
          alert('Something went wrong!');
        }
      }
    };

    if (type === 'PartyWise') {
      fetchPartiesData();
    }
  }, [agentName, type]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    setShowLoader(true);
    const RptCode = type === 'PartyWise' ? 1 : 0;

    const requestBody = {
      LogID: LogId,
      FormKey: FormKey,
      Depo: DPCode,
      PartyName: partyName === 'All' ? '' : partyName,
      AgentName: agentName === 'All' ? '' : agentName,
      ItemGroup: '',
      ItemName: '',
      Date1: '20240707',
      Date2: '20240707',
      RptCode,
    };

    const response = await axios.post(
      `${BASE_URL}/web-reports`,
      requestBody,
      config
    );
    const data = response.data.results;

    if (data.length === 0) {
      setShowAlert(true);
      setTableData([]); // Ensure tableData is cleared
      setTimeout(() => setShowAlert(false), 3000); // Show alert for 3 seconds
    } else {
      setTableData(data);
    }
  } catch (error) {
    console.error("Error fetching report data:", error);
    if (error.response && error.response.status === 401) {
      alert('Your Token is expired. Please login again!');
      navigate('/login');
    } else {
      alert('Something went wrong!');
    }
  } finally {
    setShowLoader(false);
  }
};


  const columns = [
    {
      accessor: (_row, i) => i + 1,
      Header: 'S No',
      Cell: ({ value }) => (
        <div style={{ textAlign: "center" }}>{value}</div>
      ),isInitiallyVisible: true
    },
    ...(type === 'PartyWise' && partyName === '' ? [{
      accessor: 'PartyName',
      Header: 'Party Name',
      Cell: ({ value }) => (
        <div style={{  textAlign: "left" }}>{value}</div>
      ),isInitiallyVisible: true
    }] : []),
    {
      accessor: 'Iname',
      Header: 'Item Name',
      Cell: ({ value }) => (
        <div style={{  textAlign: "left" }}>{value}</div>
      ),isInitiallyVisible: true
    },
    { 
      accessor: 'BalQty', 
      Header: 'Bal. Qty',
      Cell: ({ value }) => (
        <div style={{  textAlign: "right" }}>{parseFloat(value)}</div>
      ),isInitiallyVisible: true
    },
    { 
      accessor: 'Stock', 
      Header: 'Stock',
      Cell: ({ value }) => (
        <div style={{  textAlign: "right" }}>{parseFloat(value)}</div>
      ),isInitiallyVisible: true
    },
    { 
      accessor: 'ReqQty', 
      Header: 'Req. Qty',
      Cell: ({ value }) => (
        <div style={{textAlign: "right" }}>{parseFloat(value)}</div>
      ),isInitiallyVisible: true
    },
    { 
      accessor: 'MRP', 
      Header: 'MRP',
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>{parseFloat(value).toFixed(2)}</div>
      ),isInitiallyVisible: true
    },
  ];

  const handleTypeChange = (e) => {
    setType(e.target.value);
    setPartyName(''); // Clear party name when type changes
    setTableData([]); // Clear table data when type changes
  };

  const handlePartyChange = (e) => {
    setPartyName(e.target.value);
    setTableData([]); // Clear table data when party name changes
  };

  const handleAgentChange = (e) => {
    setAgentName(e.target.value);
    setPartyName(''); // Clear party name when agent changes
    setTableData([]); // Clear table data when agent changes
  };
console.log(types)
  return (
    <div className='p-1'>
      {showLoader && <Loader />}
      {showAlert && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-[#4FA9A7] border border-[#4FA9A7] text-white px-4 py-4 w-[50%] h-[20%] rounded-2xl flex items-center justify-center" role="alert">
            <strong className="font-bold text-lg text-center">No Data Available</strong>
          </div>
        </div>
      )}
      
      <div className="">
                <h1 className="text-xl text-center font-bold ">{targetPage.FormName}</h1>

        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-2 mb-5">
            <div>
              <Dropdown
                label="Agent Name"
                options={[{ value: '', label: 'All' }, ...agentNames.map(name => ({ value: name, label: name }))]}
                value={agentName}
                onChange={handleAgentChange}
                isDisabled={false}
              />
            </div>
            
            <div>
              <label className="text-md text-black font-semibold mb-1 block">Type</label>
              <select
                value={type}
                onChange={handleTypeChange}
                className="p-1 border border-gray-300 rounded w-[50%]"
              >
                {types.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>

            {type === 'PartyWise' && (
              <div>
                <Dropdown
                  label="Party Name"
                  options={[{ value: '', label: 'All' }, ...partyNames.map(name => ({ value: name, label: name }))]}
                  value={partyName}
                  onChange={handlePartyChange}
                  isDisabled={false}
                />
              </div>
            )}
          </div>

          <div className='grid justify-end'>
            <DynamicButton variant="primary" label="Submit" />
          </div>
        </form>
        <div className="mt-4">
          <Table columns={columns} data={tableData} />
        </div>
      </div>
    </div>
  );
};

export default BalancePurchaseOrderConsolidated;

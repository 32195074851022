// ModernDashboard.js
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Table from '../../components/Datatable/SearchFilterTable';
import Dropdown from '../../components/DynamicComponents/DynamicDropdown';
import DynamicButton from '../../components/DynamicComponents/DynamicButton';
import DynamicDatePicker from '../../components/DynamicComponents/DynamicDatePicker';
import { BASE_URI } from '../../utils/BaseURL';
import Loader from '../../utils/Loader';

const DispatchReport = () => {
  const [filter, setFilter] = useState('All');
  const [selectedDate, setSelectedDate] = useState([new Date(), new Date()]); // Initialize with current date
  const [partyNames, setPartyNames] = useState([]);
  const [rows, setRows] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();

  // Basic Information For API's
  const targetRoute = "Dispatch Report";
  let menuValue = JSON.parse(localStorage.getItem("Menu"));
  let Depot = JSON.parse(localStorage.getItem("Depo"));
  let selectedLocation = localStorage.getItem("selectedLocation");
  const targetPage = menuValue.find(item => item.FormName === targetRoute);
  const FormKey = targetPage ? targetPage.FormKey : null;
  const token = Cookies.get("token"); 
  const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
  const LogId = itemFromLocalStorage[0].LogID;
  let DPCode;
  Depot.forEach(depot => {
    if (depot.DPName === selectedLocation) {
      DPCode = depot.DPCode; 
    }
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };
  const BASE_URL = BASE_URI;

  const columns = useMemo(() => [
    {
      Header: "S No",
      align: "center",
      headerAlign: "center",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center",  }}>
          {row.index + 1}
        </div>
      ),
    isInitiallyVisible: true
    },
    {
      accessor: "BillNo",
      Header: "Bill No",
      align: "center",
      headerAlign: "center",
      Cell: ({ value }) => <div style={{ }}>{value}</div>,
    isInitiallyVisible: false

    },
    {
      accessor: "TDate",
      Header: "Date",
      align: "center",
      headerAlign: "center",
      Cell: ({ value }) => <div style={{  }}>{value}</div>,
    isInitiallyVisible: false

    },
    {
      accessor: "PartyName",
      Header: "Party Name",
      align: "left",
      headerAlign: "center",
      Cell: ({ value }) => <div style={{  }}>{value}</div>,
    isInitiallyVisible: true

    },
    {
      accessor: "Iname",
      Header: "Item Name",
      align: "left",
      headerAlign: "center",
      Cell: ({ value }) => <div style={{  }}>{value}</div>,
    isInitiallyVisible: true

    },
    {
      accessor: "BName",
      Header: "Brand",
      align: "center",
      headerAlign: "center",
    isInitiallyVisible: false

    },
    {
      accessor: "TotQty",
      Header: "Qty",
      align: "center",
      headerAlign: "center",
    isInitiallyVisible: true

    },
    {
      accessor: "Uname",
      Header: "Unit",
      align: "center",
      headerAlign: "center",
    isInitiallyVisible: false

    },
    {
      accessor: "Rate",
      Header: "Rate",
      align: "right",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          {parseFloat(value).toFixed(2)}
        </div>
      ),
    isInitiallyVisible: true

    },
    {
      accessor: "TotAmt",
      Header: "Amount",
      align: "right",
      headerAlign: "center",
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          {parseFloat(value).toFixed(2)}
        </div>
      ),
    isInitiallyVisible: true
    },
  ], []);

  useEffect(() => {
    fetchPartyNames();
  }, []);

  const fetchPartyNames = async () => {
    try {
      const requestBody1 = {
        AgentName:'',
        LogID: LogId,
        FormKey: FormKey,
        Depo: DPCode,
      };
      const response1 = await axios.post(
        `${BASE_URL}/parties`,
        requestBody1,
        config
      );
      const partyNamesData = response1.data.resultWebPartyList.map(
        (row) => row.LName
      );
      const uniquePartyNames = Array.from(new Set(partyNamesData));
      setPartyNames(uniquePartyNames);
    } catch (error) {
      if (error.response?.status === 401) {
        alert('Your Token is expired Please Login Again!!!');
        navigate('/login');
      } else {
        alert('Something went wrong!!!');
      }
      console.error("Error fetching party names:", error);
    }
  };

  const handleSearch = async () => {
    setShowLoader(true);
    try {
      const apiUrl = `${BASE_URL}/dispatchReport`;

      const startDate = selectedDate[0];
      const endDate = selectedDate[1];
      
      // Format dates as YYYYMMDD
      const formattedStartDate = `${startDate.getFullYear()}${String(startDate.getMonth() + 1).padStart(2, '0')}${String(startDate.getDate()).padStart(2, '0')}`;
      const formattedEndDate = `${endDate.getFullYear()}${String(endDate.getMonth() + 1).padStart(2, '0')}${String(endDate.getDate()).padStart(2, '0')}`;

      const requestData = {
        LogID: LogId,
        Depo: DPCode,
        Selectedvalue: filter === "All" ? "" : filter,
        StartDate: formattedStartDate,
        EndDate: formattedEndDate,
      };

      const response = await axios.post(apiUrl, requestData, config);
      const AllDispatchReport = response.data.AllDispatchReport;
      if (AllDispatchReport.length === 0) {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 1000);
      }
      const formattedRows = AllDispatchReport.map(row => ({
        ...row,
        TotAmt: parseFloat(row.TotAmt).toFixed(2),
      }));
      setRows(formattedRows);
    } catch (error) {
      if (error.response?.status === 401) {
        alert('Your Token is expired Please Login Again!!!');
        navigate('/login');
      } else {
        alert('Something went wrong!!!');
      }
      console.error("Error:", error.message);
    } finally {
      setShowLoader(false);
    }
  };

  const handleDateChange = (index, date) => {
    const newDates = [...selectedDate];
    newDates[index] = date;
    setSelectedDate(newDates);
    setRows([]); // Clear table data when date changes
  };

  const partyOptions = useMemo(
    () => [
      { value: "All", label: "All" },
      ...partyNames.map(partyName => ({
        value: partyName,
        label: partyName,
      })),
    ],
    [partyNames]
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    handleSearch();
  };

  return (
    <div className="p-3 mx-auto bg-gray-50 rounded-lg shadow-md">
      <div className="flex flex-col gap-4 mb-1">
        <h1 className="text-xl text-center font-bold">Dispatch Report</h1>
        <form onSubmit={handleSubmit} className="space-y-2">
          <div className="grid grid-cols-1 lg:grid-cols-5 gap-2">
            <Dropdown
              options={partyOptions}
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
                setRows([]); // Clear table data when filter changes
              }}
              label="Party Name"
              isDisabled={false}
            />
              <div className="grid grid-cols-2 gap-1">
                    <DynamicDatePicker
                      selectedDate={selectedDate[0]}
                      onDateChange={(date) => handleDateChange(0, date)}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      DateLabel="Start Date"
                    />
                    <DynamicDatePicker
                      selectedDate={selectedDate[1]}
                      onDateChange={(date) => handleDateChange(1, date)}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      DateLabel="End Date"
                    />
              </div>

          </div>
          <div className='grid justify-end '>
            <DynamicButton variant="primary" label="Submit" />
          </div>
        </form>
        {showLoader && <Loader />}
        <Table columns={columns} data={rows} />
      </div>
    </div>
  );
};

export default DispatchReport;

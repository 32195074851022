import React from 'react'
import { css } from '@emotion/react';
import { BeatLoader, BounceLoader, ClipLoader, HashLoader, MoonLoader, PacmanLoader, PulseLoader, RingLoader, ScaleLoader, SyncLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loader = () => {
  return (
    <>
    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-90 flex justify-center items-center p-5 z-50">
        <ScaleLoader color={'#4FA8D6'} css={override} size={300} />
    </div>
    </>
  )
}

export default Loader

import React, { useEffect } from 'react';

const DropDown = ({ id, value = '', onChange, options, label, type}) => {
  let readOnly = false;
  let selectedValue = value;
  useEffect(() => {
    console.log(type)
  },[])
  if (type === 2) {
    readOnly = true
    selectedValue = options[0].value;
  } else if (type === 3) {
    readOnly = true
    selectedValue = options[1].value;
  }
  // console.log(options[0])
  // Logic to determine read-only state and default selected value
  if (options[0]?.value !== 'AgentWise') {
    if (options.length === 1) {
      readOnly = true;
      selectedValue = '';
    } else if (options.length === 2) {
      readOnly = true;
      selectedValue = options[1].value;
    }
  }

  return (
    <div className="flex flex-col items-start space-x-1 w-full p-1">
      <label key={id} htmlFor={id} className="text-md text-black font-semibold mb-1">{label}</label>
      <select
        id={id}
        value={selectedValue}
        onChange={onChange}
        className="p-1 border rounded-md outline-none w-full"
        disabled={readOnly}
      >
        {options.map(option => (
          <option key={option.id} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default DropDown;

// For LocalHost Testing
// export const api2 = 'http://localhost:8080/api/v1'

// Updated Server 
// export const api2 = 'https://6813-13-233-98-94.ngrok-free.app'

// Linux Server
export const api2 = 'https://erp.srssoftwares.in/api/v1'





import React from 'react';

const Dropdown = ({ label, options, value, onChange, isDisabled }) => {
  // Determine the number of options
  const optionsLength = options.length;

  // Disable dropdown if there's only one option
  const dropdownDisabled = isDisabled || optionsLength <= 1;

  return (
    <div className="flex flex-col cursor-pointer">
      <label className="text-md font-medium mb-1">{label}</label>
      <select
        value={value}
        onChange={onChange}
        className="p-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-pointer w-full"
        disabled={dropdownDisabled}
      >
        {optionsLength === 0 ? (
          <option value="" disabled>All</option>
        ) : (
          options.map((option, index) => (
            <option key={index} value={option.value}>{option.label}</option>
          ))
        )}
      </select>
    </div>
  );
};

export default Dropdown;

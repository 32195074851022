import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome, FaFileAlt, FaInfoCircle, FaChartBar, FaExchangeAlt, FaDatabase, FaSearch } from 'react-icons/fa';
import { MdAccountBalanceWallet } from 'react-icons/md';
import { TbReportSearch } from 'react-icons/tb';
import { FaBalanceScale, FaUserCheck, FaClipboardList, FaRegFileAlt, FaRegListAlt, FaRegMoneyBillAlt } from 'react-icons/fa';
import { RiFileList2Line, RiShoppingCartLine, RiFileList3Line, RiFileListLine, RiFileList2Fill, RiFileListFill } from 'react-icons/ri';

// Define your icon mapping
const iconMapping = {
  1206: <FaBalanceScale style={{ color: 'gray' }} size={30} />,
  197: <FaUserCheck style={{ color: 'gray' }} size={30} />,
  204: <FaClipboardList style={{ color: 'gray' }} size={30} />,
  327: <RiFileList2Line style={{ color: 'gray' }} size={30} />,
  303: <RiShoppingCartLine style={{ color: 'gray' }} size={30} />,
  453: <RiFileList3Line style={{ color: 'gray' }} size={30} />,
  423: <RiFileListLine style={{ color: 'gray' }} size={30} />,
  1208: <FaRegFileAlt style={{ color: 'gray' }} size={30} />,
  433: <FaRegListAlt style={{ color: 'gray' }} size={30} />,
  451: <FaRegMoneyBillAlt style={{ color: 'gray' }} size={30} />,
  452: <RiFileList2Fill style={{ color: 'gray' }} size={30} />,
  467: <RiFileListFill style={{ color: 'gray' }} size={30} />,
  444: <RiFileListFill style={{ color: 'gray' }} size={30} />,
};

const QuickLinks = () => {
  const [formDetails, setFormDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredLinks, setFilteredLinks] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch and parse formDetails from localStorage
    const storedFormDetails = localStorage.getItem("Menu");
    if (storedFormDetails) {
      const details = JSON.parse(storedFormDetails);
      setFormDetails(details);
      setFilteredLinks(details.filter(item => item.SubCategory === 0));
    }
  }, []);

  useEffect(() => {
    // Filter the formDetails based on search query
    const searchResults = formDetails.filter(item =>
      item.FormName.toLowerCase().includes(searchQuery.toLowerCase()) && item.SubCategory === 0
    );
    setFilteredLinks(searchResults);
  }, [searchQuery, formDetails]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // Navigate to the first result if any
      if (filteredLinks.length > 0) {
        navigate(`/dashboard/${filteredLinks[0].FormName.replace(/\s+/g, '-').toLowerCase()}`);
      }
    }
  };

  return (
    <div className="mb-2 p-3 rounded-2xl bg-white shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg underline">Quick Links</h2>
        <div className="relative w-1/2 max-w-xs">
          <FaSearch className="absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-500" />
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            className="pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-gray-300 dark:bg-gray-200 dark:text-white w-full"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-3">
        {filteredLinks.map((item) => (
          <Link
            key={item.FormKey}
            to={`/dashboard/${item.FormName.replace(/\s+/g, '-').toLowerCase()}`}
            className={`flex items-center p-2 border border-gray-200 rounded-lg shadow-md hover:bg-[#4FA8D6] hover:text-white text-gray-900`}
          >
            {iconMapping[item.FormKey] || <FaHome size={25} />}
            <span className='lg:text-sm ml-2'>{item.FormName}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default QuickLinks;

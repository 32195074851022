import React, { useState, useEffect } from 'react';
import { FaDatabase, FaExchangeAlt, FaChartBar,  FaUserTie } from 'react-icons/fa';
import { ImMenu } from 'react-icons/im';
import { IoMdCloseCircle } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import { TbReportSearch } from 'react-icons/tb';
import { MdAccountBalanceWallet } from 'react-icons/md';
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URI } from '../../utils/BaseURL';

const Header = ({ userInfo, darkMode = true, setDarkMode = () => {} }) => {
  const [user, setUser] = useState({});
  const [activeGroup, setActiveGroup] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setUser(userInfo);
  }, [userInfo]);

  const User = JSON.parse(localStorage.getItem('userInfo'));
  const Logo = User?.[0]?.Logo;
  const sideBarMenu = JSON.parse(localStorage.getItem("Menu")) || [];
  const companyName = localStorage.getItem('cName');
  const companyLocation = localStorage.getItem('selectedLocation');
  const userName = JSON.parse(localStorage.getItem('userInfo'));

  const groupedMenuItems = sideBarMenu.reduce((acc, item) => {
    acc[item.FGroup] = acc[item.FGroup] || [];
    acc[item.FGroup].push(item);
    return acc;
  }, {});

  const handleGroupClick = (group) => {
    setActiveGroup(activeGroup === group ? null : group);
  };

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      const isLoggedIn = false;
      const LogValue = localStorage.getItem("LogValue");
      let LogId = null;
      let DPCode = null;
      const Depo = JSON.parse(localStorage.getItem("Depo"));
      const selectedLocation = localStorage.getItem("selectedLocation");

      Depo.forEach((depot) => {
        if (depot.DPName === selectedLocation) {
          DPCode = depot.DPCode;
        }
      });

      const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
      LogId = itemFromLocalStorage[0].LogID;

      const requestBody = {
        LogID: LogId,
        Depo: DPCode,
        LogNo: LogValue,
      };

      const token = Cookies.get("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };

      const BASE_URL = BASE_URI;  // Replace with your base URL
      await axios.post(`${BASE_URL}/auth/logout`, requestBody, config);
      
      Cookies.remove('token');
      localStorage.clear();
      Cookies.set('isLoggedIn', isLoggedIn);
      navigate('/login');
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <header className="relative flex items-center justify-between p-2 bg-gray-100 mb-1 w-full rounded-lg shadow-md">
      <div className="flex items-center">
        <ImMenu className="text-xl cursor-pointer text-black lg:hidden" onClick={() => setMenuOpen(true)} />
        <div className="hidden font-semibold lg:block">{companyName} <br/> {companyLocation}</div>
      </div>
      <div className="flex items-center space-x-2">
        
        <div className="relative flex items-center gap-2 cursor-pointer" onClick={() => setDropdownOpen(!dropdownOpen)}>
          <FaUserTie className="text-[#1d4ed8] text-2xl cursor-pointer" />
          <span className="">{userName[0]?.UserName}</span>
          {dropdownOpen && (
            <div className="absolute top-full right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
              <button
                onClick={handleLogout}
                className="block w-full text-left px-4 py-2 text-red-600 hover:bg-gray-200"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Sliding Panel */}
      <div
        className={`fixed top-0 left-0 h-full w-64 text-white p-2 transform ${
          menuOpen ? 'translate-x-0' : '-translate-x-64'
        } transition-transform duration-300 z-50 lg:hidden`}
      >
        <button
          onClick={() => setMenuOpen(false)}
          className="absolute top-3 right-3 text-white"
        >
          <IoMdCloseCircle size={25} />
        </button>
        <div className='bg-white h-full rounded-lg p-2 text-black shadow-2xl' >
          <Link to='/dashboard/home' onClick={handleLinkClick}>
            <img src={Logo} alt="Logo" />
          </Link>
          <div className="p-2 mt-5 overflow-y-auto">
            {Object.entries(groupedMenuItems).map(([fGroup, menuItems]) => (
              <ul key={fGroup}>
                <li className="text-black font-semibold text-md relative bg-white p-1 rounded-lg shadow-2xl mb-3" onClick={() => handleGroupClick(fGroup)}>
                  <span>
                    {fGroup === '1' && (
                      <div className='flex gap-2 items-center'>
                        <FaDatabase color='#000' size={18} />
                        Master
                      </div>
                    )}
                    {fGroup === '2' && (
                      <div className='flex gap-2 items-center'>
                        <FaExchangeAlt color='#000' size={18} />
                        Transaction
                      </div>
                    )}
                    {fGroup === '3' && (
                      <div className='flex gap-2 items-center'>
                        <FaChartBar color='#000' size={18} />
                        Display
                      </div>
                    )}
                    {fGroup === '4' && (
                      <div className='flex gap-2 items-center'>
                        <TbReportSearch color='#000' size={18} />
                        Reports
                      </div>
                    )}
                    {fGroup === '12' && (
                      <div className='flex gap-2 items-center'>
                        <MdAccountBalanceWallet color='#000' size={18} />
                        Accounts
                      </div>
                    )}
                  </span>
                  {activeGroup === fGroup && (
                    <ul className="mt-1 ml-3">
                      {menuItems.map(menuItem => (
                        <li key={menuItem.FormKey} className="text-[#4FA9B7]">
                          {menuItem.SubCategory === 1 ? (
                            <div>
                              <div className='list-roman'>{menuItem.FormName}</div>
                              <ul>
                                {menuItems
                                  .filter(item => item.SubCategory === 0 && item.FormKey.toString().startsWith(menuItem.FormKey.toString()))
                                  .map(subMenuItem => (
                                    <li key={subMenuItem.FormKey} onClick={handleLinkClick} className='bg-white text-sm p-1 rounded-xl'>
                                      <Link to={`/dashboard/${subMenuItem.FormName.replace(/\s+/g, '-').toLowerCase()}`}>{subMenuItem.FormName}</Link>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          ) : (
                            <ul className='ml-4 text-black bg-[#fff] rounded-md text-sm' onClick={handleLinkClick}>
                              <Link to={`/dashboard/${menuItem.FormName.replace(/\s+/g, '-').toLowerCase()}`}>
                                <li key={menuItem.FormKey} className='list-disc'>
                                  {menuItem.FormName}
                                </li>
                              </Link>
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>

      {/* Overlay */}
      {menuOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40 lg:hidden"
          onClick={() => setMenuOpen(false)}
        />
      )}
    </header>
  );
};

export default Header;

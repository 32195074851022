import React from 'react';

const SearchDropDown = ({ id, value , onChange, options, label }) => {
  let readOnly = false;
  let selectedValue = value;
  // Logic to determine read-only state and default selected value
  if (options.length === 1) {
    readOnly = true;
    selectedValue = '';
  } else if (options.length === 2) {
    readOnly = true;
    selectedValue = options[1].value;
    options = options.filter(option => option.label !== 'All');
  }
  return (
    <div className="flex flex-col items-start space-x-1 w-full p-1">
      <label key={id} htmlFor={id} className="text-md text-black font-semibold mb-1">{label}</label>
      <input
        list={`${id}-list`}
        id={id}
        value={selectedValue}
        onChange={onChange}
        className="p-1 border rounded-md outline-none w-full"
        disabled={readOnly}
      />
      <datalist id={`${id}-list`}>
        {options.map(option => (
          <option key={option.id} value={option.value}>
            {option.label}
          </option>
        ))}
      </datalist>
    </div>
  );
}

export default SearchDropDown;


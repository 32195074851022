import React from 'react';

const ViewButton = ({ onClick , label }) => {
  return (
    <div className='w-full'>
      <button onClick={onClick} className="bg-[#bce9ff] duration-700 hover:bg-[#3C87B1] hover:shadow-[#3C87B1] text-white w-full font-bold py-3 rounded flex items-center justify-center">
        {label}
      </button>
    </div>
  );
}

export default ViewButton;

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar = ({ selectedIcon, sidebarOpen, onFormNameClick }) => {
  const [sidebarData, setSidebarData] = useState({ headers: [], links: [] });
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [dropdownLinks, setDropdownLinks] = useState([]);

  useEffect(() => {
    const fetchSidebarData = () => {
      const sideBarMenu = JSON.parse(localStorage.getItem("Menu")) || [];

      // // Step 2: Group items by FGroup
      // const groupedByFGroup = sideBarMenu.reduce((acc, item) => {
      //   // If the group does not exist, create it
      //   if (!acc[item.FGroup]) {
      //     acc[item.FGroup] = [];
      //   }
      //   // Add the item to the respective group
      //   acc[item.FGroup].push(item);
        
      //   return acc;
      // }, {});

      // // Step 3: Log the length of each group
      // Object.keys(groupedByFGroup).forEach(fGroup => {
      //   console.log(`FGroup ${fGroup}: ${groupedByFGroup[fGroup].length} items`);
      // });
      
      const headers = [];
      const links = [];

      sideBarMenu.forEach(item => {
        if (item.SubCategory === 1) {
          headers.push(item);
        } else if (item.SubCategory === 0) {
          links.push(item);
        }
      });

      const organizedHeaders = headers.map(header => ({
        ...header,
        links: links.filter(link => link.FGroup === header.FGroup).sort((a, b) => a.SerNo - b.SerNo)
      }));

      setSidebarData({ headers: organizedHeaders, links });
    };

    fetchSidebarData();
  }, []);

  const fGroupMap = {
    master: 1,
    transaction: 2,
    display: 3,
    reports: 4,
    accounts: 12
  };

  const selectedFGroup = fGroupMap[selectedIcon];
  const filteredHeaders = sidebarData.headers.filter(header => header.FGroup === selectedFGroup);
  const filteredLinks = sidebarData.links.filter(link => link.FGroup === selectedFGroup);
  const getStartingDigit = (serNo) => serNo.toString().charAt(0);

  const filterLinksByStartingDigit = (serNo) => {
    const startingDigit = getStartingDigit(serNo);
    return filteredLinks.filter(link => getStartingDigit(link.SerNo) === startingDigit).sort((a, b) => a.SerNo - b.SerNo);
  };

  const handleDropdownToggle = (header) => {
    if (dropdownOpen === header.FormKey) {
      setDropdownOpen(null);
    } else {
      setDropdownOpen(header.FormKey);
      setDropdownLinks(filterLinksByStartingDigit(header.SerNo));
    }
  };

  const handleFormNameClick = () => {
    onFormNameClick();
  };

  return (
    <nav className={`flex flex-col p-2 h-screen transition-transform duration-300 ease-in-out ${sidebarOpen ? 'translate-x-0 ' : '-translate-x-64 space-y-5'}`}>
      {filteredHeaders.length > 0 ? (
        filteredHeaders.map((header, index) => (
          <div key={index} className="mb-1">
            <div 
              className={`text-md font-semibold cursor-pointer ${sidebarOpen ? 'text-blue-600' : 'text-gray-800'}`}
              onClick={() => handleDropdownToggle(header)}
            >
              {header.FormName}
            </div>
            {dropdownOpen === header.FormKey && sidebarOpen && (
              <div className="ml-2">
                {dropdownLinks.length > 0 ? (
                  dropdownLinks.map((link, idx) => (
                    <NavLink
                      key={idx}
                      to={`/dashboard/${link.FormName.toLowerCase().replace(/ /g, '-')}`}
                      className={({ isActive }) =>
                        isActive ? "block bg-gray-500 rounded-lg p-1 hover:text-gray-700 transition-colors duration-300 mb-2 text-sm text-white font-bold" : "block hover:text-gray-500 transition-colors duration-300 mb-2 text-sm"
                      }
                      onClick={handleFormNameClick}
                    >
                      {link.icon}
                      <span className='ml-2'>{link.FormName}</span>
                    </NavLink>
                  ))
                ) : (
                  <div className="text-gray-500">No links available</div>
                )}
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="">
          {filteredLinks.length > 0 ? (
            filteredLinks.map((link, idx) => (
              <NavLink
                key={idx}
                to={`/dashboard/${link.FormName.toLowerCase().replace(/ /g, '-')}`}
                className={({ isActive }) =>
                  isActive ? "block bg-gray-500 rounded-lg p-1 hover:text-gray-700 transition-colors duration-300 mb-2 text-white font-bold" : "block text-gray-800 hover:text-blue-500 transition-colors duration-300 mb-2"
                }
                onClick={handleFormNameClick}
              >
                {link.icon}
                <span className='ml-2'>{link.FormName}</span>
              </NavLink>
            ))
          ) : (
            <div className="text-gray-500">No links available</div>
          )}
        </div>
      )}
    </nav>
  );
};

export default Sidebar;

import { useEffect, useState } from "react";
import DropDown from "../../utils/Common/Dropdown";
import axios from "axios";
import Cookies from 'js-cookie';
import { api1, api2 } from "../../utils/Common/apiURL";
import { useNavigate } from "react-router-dom";

const generateShortAlphanumericId = () => {
    const alphanumericCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let shortId = '';
    for (let i = 0; i < 11; i++) {
      shortId += alphanumericCharacters.charAt(Math.floor(Math.random() * alphanumericCharacters.length));
    }
    return shortId;
};

const EditModal = ({ rowData, open, itemData, handleClose, handleSave , partyCode }) => {
    console.log('RowData', open)
    const [editedData, setEditedData] = useState({ });
    const navigate = useNavigate();
    useEffect(() => {
        if (rowData) {
            setEditedData({ ...rowData });
      }
    }, [rowData]);
    const [setPartyCode] = useState('');
    // console.log('Data',itemData)
    const [id, setId] = useState(1);
    const [orderNumber, setOrderNumber] = useState(0);
    const [orderId, setOrderId] = useState(generateShortAlphanumericId());
    const [orderDate, setOrderDate] = useState(new Date().toLocaleDateString('en-GB').split('-').reverse().join('-'));
    // const [partyName, setPartyName] = useState('');
    
    const [itemGroup, setItemGroup] = useState('');
    const [itemName, setItemName] = useState('');
    const [quantity, setQuantity] = useState('1');
    const [rate, setRate] = useState('');
    const [packing, setPacking] = useState('');
    const [MRP, setMRP] = useState('');
    const [unit, setUnit] = useState('');
    const [amount, setAmount] = useState('');
    const [GST, setGST] = useState('');
    const [dropdown1Value, setDropdown1Value] = useState('');
    const [dropdown2Value, setDropdown2Value] = useState('');
    const [partyNames, setPartyNames] = useState([]);
    const [itemNames, setitemNames] = useState([]);
    const [displayItem, setDisplayItem] = useState([]);
   
    const [rows, setRows] = useState([]);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editedRowData, setEditedRowData] = useState({});
    const [percentage, setPercentage] = useState('');
    const [totalDiscount, setTotalDiscount] = useState('');
    const [afterDiscount, setAfterDiscount] = useState('');
    // Define states for TDS
    const [tdsPercentage, setTdsPercentage] = useState('');
    const [tdsAmount, setTdsAmount] = useState('');
  
    // Define states for SD
    const [sdPercentage, setSdPercentage] = useState('');
    const [sdAmount, setSdAmount] = useState('');
  
    const [taxableValue, setTaxableValue] = useState('');
  
    let menuValue = JSON.parse(localStorage.getItem('Menu'));
    let Depo = JSON.parse(localStorage.getItem('Depo'));
    let selectedLocation = localStorage.getItem('selectedLocation');
    let targetRoute = 'Sale Order';
  
   // Handle closing the edit modal
   const handleCloseModal = () => {
    setEditModalOpen(false);
  };
  
    const handleDropdown1Change = (e) => {
      const value = e.target.value;
      setDropdown1Value(value);
      // Update editedData with the new party name
      setEditedData(prevData => ({
          ...prevData,
          partyName: value
      }));
    };
  const handleDropdown2Change = (e) => {
      setDropdown2Value(e.target.value);
      const selectedItemName = e.target.value;
      const selectedItem = itemData.find(item => item.IName === selectedItemName);
      if (selectedItem) {
        setDisplayItem(selectedItem);
        setUnit(selectedItem.UName);
        setPacking(selectedItem.Packing);
        setMRP(selectedItem.MRP);
        setRate(selectedItem.Rate);
        setAmount(selectedItem.Amount);
        setGST(selectedItem.GST);
        // Similarly, set other state values based on the selected item
    }
  };

  let items = []
if(editedData.original){
  const selectedItemGroup = editedData.original.itemGroup;
  items = itemData
    .filter(item => item.IName === selectedItemGroup)
    .map(item => ({ value: item.IName, label: item.IName }));
}
  // items.unshift({ value: '', label: 'Select Item Name' });
  
  const selectedRow = itemData.find(item => item.IName === dropdown2Value);
  
  useEffect(() => {
    setDisplayItem(selectedRow);
  }, [selectedRow]);

  let parties = []
  if(editedData.original){
     parties = partyNames
      .filter(party => party === editedData.original.partyName)
      .map(partyName => ({ value: partyName, label: partyName }));
    
  }

   
  
    const handlePercentageChange = (e) => {
      const newPercentage = e.target.value;
      setPercentage(newPercentage);
      const amount = rate * quantity
      const newTotalDiscount = rate * quantity * (newPercentage / 100);
      var afterDiscount = amount - newTotalDiscount
      setAfterDiscount(afterDiscount)
      setTotalDiscount(newTotalDiscount);
    };
    
    const calculateGSTValue = () => {
      if (!editedData.original || !editedData.original.GST) return ''; // Return empty string if displayItem or GSTName is undefined
    
      const gstPercentage = editedData.original.GST.split(' ')[1].replace('%', '');
      
      const gstValue = (calculateTaxableValue() * gstPercentage / 100);
      return gstValue.toFixed(2);
    };
  
    const calculateNetTotal = () => {
      if (!editedData.original || !editedData.original.GST) return ''; // Return empty string if displayItem or GSTName is undefined
  
      const gstPercentage = editedData.original.GST.split(' ')[1].replace('%', '');
      const mrp = editedData.original.MRP;
      const mrpDisc = editedData.original.MRPDisc;
      const td = editedData.original.TD;
      const sd = editedData.original.SD;

      const netTotal = parseFloat(calculateTaxableValue()) + parseFloat(calculateGSTValue());
      return netTotal.toFixed(2);
    };
    const calculateTaxableValue = () => {
      if (!editedData.original) return ''; // Return empty string if displayItem is undefined
      const price = (editedData.original.MRP - (editedData.original.MRP * (editedData.original.MRPDisc / 100)))
      const tdPercentage = editedData.original.tdsAmount;
      const sdPercentage = editedData.original.sdAmount;
    
      // Calculate Taxable Amount after discount
      const taxableAmountAfterDiscount = price * quantity;

      // Calculate Taxable Amount after TD
      const taxableAmountAfterTD = taxableAmountAfterDiscount - (taxableAmountAfterDiscount * (tdPercentage / 100)).toFixed(2);

      const taxableAmountAfterSD = taxableAmountAfterTD - (taxableAmountAfterTD * (sdPercentage / 100))
  
    
      // Return the calculated value with 2 decimal places
      return taxableAmountAfterSD.toFixed(2);
    };


    const calculateRate = () => {
      if (!editedData.original) return '';
      const price = (editedData.original.MRP - (editedData.original.MRP * (editedData.original.MRPDisc / 100)))
      return price.toFixed(2)
    }

    const calculateAmount = () => {
      if (!editedData.original) return '';
      const price = (editedData.original.MRP - (editedData.original.MRP * (editedData.original.MRPDisc / 100)))
      return (price * quantity).toFixed(2)
    }
  
    const grandTotal = rows.reduce((total, row) => total + parseFloat(row.totalAmt), 0);
    
     let grandTotalRow = {
        id: '',
        MRPDisc:'', // Keep it empty for consistency
        itemGroup: 'Grand Total',
        totalAmt: grandTotal.toFixed(2), // Format amount with two decimal places
        actions: '_', // Empty actions column for the grand total row
      };
      const handleSave1 = (id) => {
        // Log all the updated values
        const newItem = {
          id:id,
          orderDate,
          partyName: editedData.original.partyName,
          itemGroup: editedData.original.itemGroup,
          quantity:quantity,
          rate:calculateRate(),
          unit:editedData.original.unit,
          packing:editedData.original.packing,
          MRP:editedData.original.MRP,
          MRPDisc:editedData.original.MRPDisc,
          amount: calculateRate() * quantity,
          tdsAmount:editedData.original.tdsAmount,
          sdAmount:editedData.original.sdAmount,            
          netTaxableValue: calculateTaxableValue(),
          GST:editedData.original.GST,
          totalAmt:calculateNetTotal()
        };
        console.log(newItem) 
        setQuantity('1') 
        handleSave(newItem)
      };
  
    
    return (
      <div>
          <div className={`overlay ${open ? 'block' : 'hidden'}`} />
          <div className={`modal ${open ? 'block' : 'hidden'} h-[90%] w-[95%] md:w-[90%] md:h-[80%] lg:w-[60%] lg:h-[60%]`} style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
              <div className="modal-content" style={{ backgroundColor: '#4FA9A7', padding: '20px', borderRadius: '15px' ,width:'100%', height:'100%' }}>
              <div className="grid grid-cols-1 gap-4 bg-[#4FA9A7] mt-5 lg:mt-10 rounded-lg">
              <div>
              <DropDown
                      id="dropdown2"
                      // value={ dropdown2Value ||  editedData.original.itemGroup }
                      value={editedData.original && editedData.original.itemGroup ? editedData.original.itemGroup : dropdown2Value}
                      onChange={handleDropdown2Change}
                      options={items}
                      label="Item name"
              />
              </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
            <div className='flex'>
                <div className='flex-1 mr-2'>
                <label htmlFor="quantity" className="block">Quantity:</label>
                <input
                type="number"
                id="quantity"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                className="border border-gray-300 rounded-md px-2 py-1 w-full"
                />
                </div>
                <div className='flex-1 mr-2'>
                    <label htmlFor="unit" className="block">Unit:</label>
                    <input
                    type="text"
                    id="unit"
                    value={editedData.original ? editedData.original.unit : unit}
                    onChange={(e) => setUnit(e.target.value)}
                    className="border border-gray-300 rounded-md px-2 py-1 w-full"
                    readOnly
                    />
                </div>
            </div>

                    <div>
                        <label htmlFor="packing" className="block">Packing:</label>
                        <input
                        type="text"
                        id="packing"
                        value={editedData.original ? editedData.original.packing : packing}
                        onChange={(e) => setPacking(e.target.value)}
                        className="border border-gray-300 rounded-md px-2 py-1 w-full"
                        readOnly
                        />
                    </div>
                    <div className='flex'>
                        <div className="flex-1 mr-2">
                        <label htmlFor="MRP" className="block">MRP:</label>
                        <input
                            type="number"
                            id="MRP"
                            // value={editedData.original ? editedData.original.MRP : MRP}
                            value={editedData.original ? editedData.original.MRP : MRP}
                            onChange={(e) => setMRP(e.target.value)}
                            className="border border-gray-300 rounded-md px-2 py-1 w-full"
                            readOnly
                        />
                        </div>
                        <div className="flex-1 mr-2">
                            <label htmlFor="percentage" className="block">MRP Disc.:</label>
                            <input
                            type="number"
                            id="percentage"
                            value={editedData.original ? editedData.original.MRPDisc : ''}
                            onChange={handlePercentageChange}
                            className="border border-gray-300 rounded-md px-2 py-1 w-full"
                            readOnly
                            />
                        </div>
                    </div>
                    <div className='flex'>
                        <div className='flex-1 mr-2'>
                            <label htmlFor="rate" className="block">Price:</label>
                            <input
                            type="number"
                            id="rate"
                            value={calculateRate()}
                            // value={itemRate}
                            onChange={(e) => setRate(e.target.value)}
                            className="border border-gray-300 rounded-md px-2 py-1 w-full"
                            readOnly
                            />
                        </div>
                        <div className='flex-1 mr-2'>
                            <label htmlFor="amount" className="block">Amount:</label>
                            <input
                            type="number"
                            id="amount"
                            value={calculateAmount()}
                            onChange={(e) => setAmount(e.target.value)}
                            className="border border-gray-300 rounded-md px-2 py-1 w-full"
                            readOnly
                            />
                        </div>
                    </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="flex">

                    <div className="flex-1 mr-2">
                    <label htmlFor="tdsAmount" className="block">TD (%):</label>
                    <input
                        type="number"
                        id="tdsAmount"
                        value={editedData.original ? editedData.original.tdsAmount : ''}
                        onChange={(e) => setTdsAmount(e.target.value)}
                        className="border border-gray-300 rounded-md px-2 py-1 w-full"
                        readOnly
                    />
                    </div>
                    <div className="flex-1 mr-2">
                    <label htmlFor="sdAmount" className="block">SD (%):</label>
                    <input
                    type="number"
                    id="sdAmount"
                    value={editedData.original ? editedData.original.sdAmount : ''}
                    onChange={(e) => setSdAmount(e.target.value)}
                    className="border border-gray-300 rounded-md px-2 py-1 w-full"
                    readOnly
                    />
                    </div>

            </div>
            
            <div>
                        <label htmlFor="amount" className="block"> Taxable Value:</label>
                        <input
                        type="number"
                        id="amount"
                        value={calculateTaxableValue()}
                        // value={editedData.original ? editedData.original.netTaxableValue : ''}
                        onChange={(e) => setAmount(e.target.value)}
                        className="border border-gray-300 rounded-md px-2 py-1 w-full"
                        readOnly
                        />
            </div>
                
                <div className='flex-1 mr-2'>
                            <label htmlFor="GSTValue" className="block">{displayItem ? displayItem.GSTName : 'GST'}:</label>
                            <input
                            type="text"
                            id="GSTValue"
                            value={editedData.original ? editedData.original.GST : ''}
                            onChange={(e) => setGST(e.target.value)}
                            className="border border-gray-300 rounded-md px-2 py-1 w-full"
                            readOnly
                            />
                </div>
                <div className='flex-1 mr-2'>
                            <label htmlFor="totalAmount" className="block">Total Amount:</label>
                            <input
                            type="text"
                            id="totalAmount"
                            value={calculateNetTotal()}
                            // value={editedData.original ? editedData.original.totalAmt : ''}
                            onChange={(e) => setGST(e.target.value)}
                            className="border border-gray-300 rounded-md px-2 py-1 w-full"
                            readOnly
                            />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-[50px]">
                        <div className='bg-green-600 p-2 rounded-lg text-center cursor-pointer' onClick={() => handleSave1(editedData.original.id)}>
                            <button  className='text-white font-semibold'>Update</button>
                        </div>
                        <div className='bg-red-600 p-2 rounded-lg text-center cursor-pointer' onClick={handleClose}>
                            <button  className='text-white font-semibold'>Cancel</button>
                        </div>
              </div>
              </div>
          </div>
      </div>
  );
  
};
export default EditModal;

import React from 'react';

const buttonStyles = {
  primary: 'bg-blue-500 text-white hover:bg-blue-600',
  secondary: 'bg-green-500 text-white hover:bg-green-600',
  tertiary: 'bg-red-500 text-white hover:bg-red-600',
};

const DynamicButton = ({ variant = 'primary', label, onClick }) => (
  <button
    onClick={onClick}
    className={`py-2 px-4 rounded-md font-semibold transition-colors ${buttonStyles[variant]}`}
  >
    {label}
  </button>
);

export default DynamicButton;

import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { BASE_URI } from '../../utils/BaseURL';
import axios from 'axios';

const DoughnutChartComponent = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Monthly Data',
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: []
      }
    ]
  });
  const navigate = useNavigate();
  const startDate = new Date();
  
  // Basic Information For API's
  let Depot = JSON.parse(localStorage.getItem("Depo"));
  let selectedLocation = localStorage.getItem("selectedLocation");
  const FormKey = 0;
  const token = Cookies.get("token");
  var LogId;
  var DPCode;
  Depot.forEach((depot) => {
    if (depot.DPName === selectedLocation) {
      DPCode = depot.DPCode;
    }
  });
  const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
  LogId = itemFromLocalStorage[0].LogID;
  
  // Config for API's
  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };
  const BASE_URL = BASE_URI;

  // Fetch data from API
  const fetchData = async () => {
    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, "0");
    const day = String(startDate.getDate()).padStart(2, "0");
    const formattedStartDate = `${year}${month}${day}`;

    try {
      const requestBody = {
        "LogID": LogId,
        "FormKey": FormKey,
        "Depo": DPCode,
        "PartyName": '',
        "AgentName": '',
        "ItemGroup": '',
        "ItemName": '',
        "Date1": formattedStartDate,
        "Date2": formattedStartDate,
        "RptCode": 0
      };
      const response = await axios.post(
        `${BASE_URL}/web-reports`,
        requestBody,
        config
      );
      const data = response.data.results;

      // Assuming response.data.results is an array of objects like [{ "": [5, 11865] }, { "": [6, 12500] }, { "": [7, 58699.8] }]
      const monthMap = {
        1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June',
        7: 'July', 8: 'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December'
      };

      const labels = [];
      const datasetData = [];
      data.forEach(item => {
        const [month, sale] = item[""];
        if (month in monthMap) {
          labels.push(monthMap[month]);
          datasetData.push(sale);
        }
      });

      const backgroundColor = [
        '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
        '#FFCD56', '#36A2EB', '#FF6384', '#4BC0C0', '#9966FF', '#FF9F40'
      ];

      setChartData({
        labels: labels,
        datasets: [
          {
            label: 'Monthly Data',
            data: datasetData,
            backgroundColor: backgroundColor.slice(0, labels.length),
            hoverBackgroundColor: backgroundColor.slice(0, labels.length).map(color => `${color}CC`)
          }
        ]
      });
    } catch (error) {
      console.error("Error:", error.message);
      if (error.response.status === 401) {
        alert('Your Token is expired Please Login Again!!!');
        // navigate('/login');
      } else {
        alert('Something went wrong!!!');
      }
    }
  };

    useEffect(() => {
        fetchData();
    }, []);
    
    const totalSales = chartData.datasets[0].data.reduce((total, value) => total + value, 0);

  return (
    <div className="w-full mx-auto  rounded-xl p-2 flex flex-col md:flex-row items-center cursor-pointer">
    <div className="w-full md:w-2/3">
    <h2 className="text-lg underline mb-2 ">Monthly Sales</h2>
        <Doughnut data={chartData} />
      </div>
      <div className="mt-6 md:mt-0 ml-[-20px] rounded-2xl">
        <table className="rounded-2xl text-sm">
          <thead>
            <tr>
              <th className="text-left py-2 px-2 ">Month</th>
              <th className="text-left py-2 px-2 ">Sales (₹)</th>
            </tr>
          </thead>
          <tbody>
            {chartData.labels.map((label, index) => (
              <tr key={index}>
                <td className="text-left py-2 px-2" style={{ color: chartData.datasets[0].backgroundColor[index] }}>{label}</td>
                <td className="text-right py-2 text-sm px-2">{`₹${chartData.datasets[0].data[index].toFixed(2)}`}</td>
              </tr>
            ))}
            <tr>
              <td className="text-left py-2 px-2 ">Total</td>
              <td className="text-left py-2 px-2 text-sm">{`₹${totalSales.toFixed(2)}`}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DoughnutChartComponent;

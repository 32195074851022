import React, { useState, useEffect } from 'react';
import Table from '../../components/Datatable/SearchFilterTable';
import Dropdown from '../../components/DynamicComponents/DynamicDropdown';
import DynamicButton from '../../components/DynamicComponents/DynamicButton';
import DynamicDatePicker from '../../components/DynamicComponents/DynamicDatePicker';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { BASE_URI } from '../../utils/BaseURL';
import Loader from '../../utils/Loader';

const columns = [
  {
    Header: "S No",
    Cell: ({ row }) => (
      <div style={{ textAlign: "center" }}>
        {row.original.isTotalRow ? "" : row.index + 1}
      </div>
    ),
    isInitiallyVisible: true
  },
  {
    Header: "Particular/Name",
    accessor: "Particulars",
    isInitiallyVisible: true
  },
  {
    accessor: "Amount",
    Header: "Amount",
    Cell: ({ value }) => (
      <div style={{ textAlign: "right" }}>
        {typeof value === 'number' ? value.toFixed(2) : value}
      </div>
    ),
    isInitiallyVisible: true
  },
  {
    Header: "",
    accessor: "extra",
    Cell: ({ value }) => (
      <div style={{ textAlign: "center" }}>{value}</div>
    ),
    isInitiallyVisible: true
  },
];

const DebtorList = () => {
  const [filter, setFilter] = useState('Agentwise');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [rows, setRows] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  
  const dropdownOptions1 = [
    { label: 'Agentwise', value: 'Agentwise' },
    { label: 'Partywise', value: 'Partywise' },
    { label: 'Citywise', value: 'Citywise' },
    { label: 'Statewise', value: 'Statewise' }
  ];

  // Basic Information For API's
  let targetRoute = "Debtors List";
  let menuValue = JSON.parse(localStorage.getItem("Menu"));
  let Depot = JSON.parse(localStorage.getItem("Depo"));
  let selectedLocation = localStorage.getItem("selectedLocation");
  const navigate = useNavigate();

  const targetPage = menuValue.find(
    (item) => item.FormName === targetRoute
  );
  const token = Cookies.get("token"); 
  var LogId;
  var DPCode;
  Depot.forEach((depot) => {
    if (depot.DPName === selectedLocation) {
      DPCode = depot.DPCode; 
    }
  });
  const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
  LogId = itemFromLocalStorage[0].LogID;

  // Config for API's
  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };
  const BASE_URL = BASE_URI;
  let debtorsCode = 0;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
    const day = String(selectedDate.getDate()).padStart(2, "0");
    const formattedStartDate = `${year}${month}${day}`;
    const selectedIndex = dropdownOptions1.findIndex(option => option.value === filter);

    const requestBody = {
      LogId: LogId,
      Depo: DPCode,
      DebtorIndex: debtorsCode,
      SelectedIndex: selectedIndex,
      StartDate: formattedStartDate,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/debtorsList`,
        requestBody,
        config
      );
      const AllDebtors = response.data.AllDebtors.map(debtor => ({
        ...debtor,
        Amount: Math.abs(parseFloat(debtor.Amount)),
        extra: parseFloat(debtor.Amount) > 0 ? "Dr" : "Cr"
      }));
      setRows([...AllDebtors, calculateTotalRow(AllDebtors)]);
    } catch (error) {
      if (error.response.status === 401) {
        alert('Your Token is expired Please Login Again!!!');
        navigate('/login');
      } else {
        alert('Something went wrong!!!');
      }
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setRows([]); // Clear table data
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setRows([]); // Clear table data
  };

  const calculateTotalRow = (rows) => {
    const totalAmount = rows.reduce((sum, row) => {
      return sum + (row.extra === "Dr" ? parseFloat(row.Amount) : -parseFloat(row.Amount));
    }, 0);
    return {
      Particulars: "Total",
      Amount: totalAmount.toFixed(2),
      extra: "",
      isTotalRow: true, // Add a flag to identify the total row
    };
  };

  return (
    <div className="p-3 mx-auto bg-gray-50 rounded-lg shadow-md ">
      {showLoader && <Loader />}
      <div className="flex flex-col gap-4 mb-1">
        <h1 className="text-xl text-center font-bold ">{targetPage.FormName}</h1>
        <form onSubmit={handleSubmit} className="space-y-2">
          <div className="grid grid-cols-2 lg:grid-cols-5 space-x-2 ">
            <Dropdown
              options={dropdownOptions1}
              value={filter}
              onChange={handleFilterChange}
              label="Filter"
              isDisabled={false}
            />
            <DynamicDatePicker
              selectedDate={selectedDate}
              onDateChange={handleDateChange}
              maxDate={new Date()}
              dateFormat="dd/MM/yyyy"
              DateLabel="Date"
            />
          </div>
          <div className='grid justify-end '>
            <DynamicButton variant="primary" label="Submit" />
          </div>
        </form>
      </div>
      <Table columns={columns} data={rows} />
    </div>
  );
};

export default DebtorList;

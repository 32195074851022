import React, { useState, useRef, useEffect } from 'react';

const InputDropdown = ({ label, options = [], value, onChange, isDisabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value || '');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    // Update filtered options based on input value
    setFilteredOptions(
      inputValue
        ? options.filter(option =>
            option.toLowerCase().includes(inputValue.toLowerCase())
          )
        : options
    );
  }, [inputValue, options]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setIsOpen(true); // Open the dropdown when input changes
  };

  const handleOptionClick = (option) => {
    setInputValue(option);
    setIsOpen(false);
    onChange(option); // Pass the selected option directly
  };

  const handleInputFocus = () => {
    setIsOpen(true); // Open dropdown on input focus
  };

  return (
    <div className="relative flex flex-col cursor-pointer" ref={dropdownRef}>
      <label className="text-md font-medium mb-1">{label}</label>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onClick={() => setIsOpen(!isOpen)}
        onFocus={handleInputFocus}
        className="p-1 mb-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Type to search..."
        disabled={isDisabled}
      />
      {isOpen && (
        <div className="absolute top-full mt-1 bg-white border border-gray-300 rounded-md max-h-40 overflow-y-auto">
          {filteredOptions.length === 0 && inputValue ? (
            <div className="p-2 text-gray-500">No options available</div>
          ) : (
            filteredOptions.map((option, index) => (
              <div
                key={index}
                className="p-2 hover:bg-blue-500 hover:text-white cursor-pointer"
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default InputDropdown;

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

const DynamicDatePicker = ({
  selectedDate: propSelectedDate,
  onDateChange,
  minDate,
  maxDate,
  dateFormat = 'MM/dd/yyyy',
  showTimeSelect = false,
  isRange = false,
  startDate: propStartDate,
  endDate: propEndDate,
  onRangeChange,
  startDateLabel = "Start Date",
  endDateLabel = "End Date",
  DateLabel = "Select Date"
}) => {
  // Initialize current date
  const currentDate = new Date();

  // Set default states
  const [selectedDate, setSelectedDate] = useState(propSelectedDate || currentDate);
  const [startDate, setStartDate] = useState(propStartDate || currentDate);
  const [endDate, setEndDate] = useState(propEndDate || currentDate);

  useEffect(() => {
    if (propSelectedDate) {
      setSelectedDate(propSelectedDate);
    }
  }, [propSelectedDate]);

  useEffect(() => {
    if (propStartDate) {
      setStartDate(propStartDate);
    }
  }, [propStartDate]);

  useEffect(() => {
    if (propEndDate) {
      setEndDate(propEndDate);
    }
  }, [propEndDate]);

  const handleDateChange = (date) => {
    if (isRange) {
      if (!startDate || date < startDate) {
        setStartDate(date);
        setEndDate(null);
        onRangeChange({ startDate: date, endDate: null });
      } else {
        setEndDate(date);
        onRangeChange({ startDate, endDate: date });
      }
    } else {
      setSelectedDate(date);
      onDateChange(date);
    }
  };

  return (
    <div className="relative space-y-4">
      {!isRange ? (
        <div className="flex flex-col">
          <label className="text-md font-medium mb-1">{DateLabel}</label>
          <DatePicker
            selected={selectedDate}
            onChange={date => {
              setSelectedDate(date);
              onDateChange(date);
            }}
            minDate={minDate}
            maxDate={maxDate}
            dateFormat={dateFormat}
            showTimeSelect={showTimeSelect}
            className="p-1 border bg-gray-300 font-bold rounded-md mr-5 w-full"
            placeholderText="Select date"
          />
        </div>
      ) : (
        <div className="flex flex-col">
          <label className="text-md font-medium mb-1">{startDateLabel}</label>
          <DatePicker
            selected={startDate}
            onChange={date => handleDateChange(date)}
            minDate={minDate}
            maxDate={maxDate}
            dateFormat={dateFormat}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            showTimeSelect={showTimeSelect}
            className="p-1 border border-gray-300 rounded-md mr-5"
            placeholderText="Select start date"
          />
          <label className="text-lg font-medium mt-4 mb-1">{endDateLabel}</label>
          <DatePicker
            selected={endDate}
            onChange={date => handleDateChange(date)}
            minDate={startDate}  // Ensure endDate cannot be before startDate
            maxDate={maxDate}
            dateFormat={dateFormat}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            showTimeSelect={showTimeSelect}
            className="p-1 border border-gray-300 rounded-md mt-2 mr-2"
            placeholderText="Select end date"
          />
        </div>
      )}
    </div>
  );
};

export default DynamicDatePicker;

import React, { useState, useEffect } from 'react';
import Table from '../../components/Datatable/SearchFilterTable';
import Dropdown from '../../components/DynamicComponents/DynamicDropdown';
import DynamicButton from '../../components/DynamicComponents/DynamicButton';
import Loader from '../../utils/Loader';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { BASE_URI } from '../../utils/BaseURL';

const columns = [
  {
    Header: "S No",
    accessor: "sNo",
    align: "center",
    headerAlign: "center",
    Cell: ({ row }) => (
      <div style={{ textAlign: "center",  }}>
        {row.original.isTotalRow ? "" : row.index + 1}
      </div>
    ),
    isInitiallyVisible: true
  },
  {
    accessor: "BillRemark",
    Header: "Doc No",
    align: "left",
    headerAlign: "center",
    Cell: ({ value }) => (
      <div style={{  textAlign: "center" }}>{value}</div>
    ),
    isInitiallyVisible: false
  },
  {
    accessor: "TrDate",
    Header: "Doc Date",
    align: "center",
    headerAlign: "center",
    Cell: ({ value }) => (
      <div style={{  textAlign: "center" }}>{value}</div>
    ),
    isInitiallyVisible: false
  },
  {
    accessor: "AName",
    Header: "Agent Name",
    align: "left",
    headerAlign: "center",
    Cell: ({ value }) => <div style={{ }}>{value}</div>,
    isInitiallyVisible: false
  },
  {
    accessor: "LName",
    Header: "Party Name",
    align: "left",
    headerAlign: "center",
    Cell: ({ value }) => <div style={{ }}>{value}</div>,
    isInitiallyVisible: true
  },
  {
    accessor: "DrAmt",
    Header: "Debit",
    align: "right",
    headerAlign: "center",
    Cell: ({ value }) => (
      <div style={{  textAlign: "right" }}>
        {parseFloat(value).toFixed(2)}
      </div>
    ),
    isInitiallyVisible: true
  },
  {
    accessor: "CrAmt",
    Header: "Credit",
    align: "right",
    headerAlign: "center",
    Cell: ({ value }) => (
      <div style={{ textAlign: "right" }}>
        {parseFloat(value).toFixed(2)}
      </div>
    ),
    isInitiallyVisible: true
  },
  {
    accessor: "Balance",
    Header: "Balance",
    align: "right",
    headerAlign: "center",
    Cell: ({ row }) => {
      // Get debit and credit values from the row
      const debit = parseFloat(row.original.DrAmt) || 0;
      const credit = parseFloat(row.original.CrAmt) || 0;

      // Calculate balance
      const balance = debit - credit;

      // Format the balance value
      const displayBalance = balance.toFixed(2);

      return (
        <div style={{ textAlign: "right" }}>
          {displayBalance}
        </div>
      );
    },
    isInitiallyVisible: true
  },
  {
    accessor: "PDays",
    Header: "Days",
    align: "center",
    headerAlign: "center",
    Cell: ({ value }) => (
      <div 
        style={{
          textAlign: "center", 
          backgroundColor: parseFloat(value) > 60 ? 'bg-gray-200' : 'transparent',
          color: parseFloat(value) > 60 ? 'text-white' : 'inherit'
        }}
      >
        {value}
      </div>
    ),
    isInitiallyVisible: true
  },
];

const ReceivableBills = () => {
  const [filter, setFilter] = useState('Agentwise'); // Default filter to 'Agentwise'
  const [dropdown2, setDropdown2] = useState('All'); // Default dropdown value
  const [selectedDate, setSelectedDate] = useState(null);
  const [agentNames, setAgentNames] = useState([]);
  const [partyNames, setPartyNames] = useState([]);
  const [dropdown2Options, setDropdown2Options] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [rows, setRows] = useState([]);

  // Basic Information For API's
  let targetRoute = "Bills Outstanding [Receivable]";
  let menuValue = JSON.parse(localStorage.getItem("Menu"));
  let Depot = JSON.parse(localStorage.getItem("Depo"));
  let selectedLocation = localStorage.getItem("selectedLocation");
  const navigate = useNavigate();

  const targetPage = menuValue.find(item => item.FormName === targetRoute);
  const FormKey = targetPage ? targetPage.FormKey : null;
  const token = Cookies.get("token"); 
  let LogId, DPCode, Type;

  Depot.forEach(depot => {
    if (depot.DPName === selectedLocation) {
      DPCode = depot.DPCode; 
    }
  });

  const itemFromLocalStorage = JSON.parse(localStorage.getItem('userInfo'));
  LogId = itemFromLocalStorage[0].LogID;
  Type = itemFromLocalStorage[0].UserType;

  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };

  const BASE_URL = BASE_URI;

  useEffect(() => {
    fetchAgentNames();
    fetchPartyNames();
  }, []);

  useEffect(() => {
    if (filter === 'Agentwise') {
      const formattedAgentNames = agentNames.map(agent => ({
        value: agent.Aname,
        label: agent.Aname,
      }));
      setDropdown2Options([
        { value: 'All', label: 'All' },
        ...formattedAgentNames,
      ]);
    } else if (filter === 'Partywise') {
      const formattedPartyNames = partyNames.map(party => ({
        value: party.LName,
        label: party.LName,
      }));
      setDropdown2Options([
        { value: 'All', label: 'All' },
        ...formattedPartyNames,
      ]);
    }
  }, [filter, agentNames, partyNames]);

  useEffect(() => {
    // Clear rows when filter or dropdown2 value changes
    setRows([]);
  }, [filter, dropdown2]);

  const fetchAgentNames = async () => {
    setShowLoader(true);
    try {
      const requestBody = { LogID: LogId, FormKey: FormKey, Depo: DPCode };
      const response = await axios.post(`${BASE_URL}/agents`, requestBody, config);
      const Agents = response.data.resultWebAgentList;
      setAgentNames(Agents);
    } catch (error) {
      console.error('Error fetching agent names:', error);
    } finally {
      setShowLoader(false);
    }
  };

  const fetchPartyNames = async () => {
    setShowLoader(true);
    try {
      const requestBody1 = {
        LogID: LogId,
        FormKey: FormKey,
        Depo: DPCode,
        AgentName: "",
      };
      const response = await axios.post(`${BASE_URL}/parties`, requestBody1, config);
      const Parties = response.data.resultWebPartyList;
      setPartyNames(Parties);
    } catch (error) {
      console.error('Error fetching party names:', error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setShowLoader(true);
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); 
    const day = String(date.getDate()).padStart(2, "0");

    const formattedStartDate = `${year}${month}${day}`;

    const AgentPartyCode = filter === "Agentwise" ? 0 : 1;
    const Value = dropdown2 === "All" ? "" : dropdown2;

    try {
      const requestBody = {
        LogID: LogId,
        Depo: DPCode,
        OutstandingIndex: 0,
        SelectedIndex: AgentPartyCode,
        Code: Value,
        StartDate: formattedStartDate,
      };

      const response = await axios.post(`${BASE_URL}/outstandingBill`, requestBody, config);
      const ReceivableBills = response.data.AllOutstandingBills;

      if (ReceivableBills.length === 0) {
        alert('No receivable bills found.');
        setRows([]);
      } else {
        // Group and format the data
        setRows(groupByCompany(ReceivableBills));
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert('Your token is expired. Please log in again.');
        navigate('/login');
      } else {
        alert('Something went wrong. Please try again.');
      }
      console.error("Error:", error.message);
    } finally {
      setShowLoader(false);
    }
  };

  const groupByCompany = (data) => {
    const groupedData = data.reduce((acc, row) => {
      const companyName = row.LName;

      if (!acc[companyName]) {
        acc[companyName] = [];
      }
      acc[companyName].push(row);
      return acc;
    }, {});

    const groupedWithTotals = Object.keys(groupedData).map(companyName => {
      const rows = groupedData[companyName];
      const totalDebit = rows.reduce((sum, row) => sum + parseFloat(row.DrAmt || 0), 0);
      const totalCredit = rows.reduce((sum, row) => sum + parseFloat(row.CrAmt || 0), 0);
      const totalBalance = totalDebit - totalCredit;

      // Add total row for the company
      return [
        ...rows,
        {
          sNo: '',
          BillRemark:'',
          TrDate: '',
          AName: '',
          LName:  `Total`,
          DrAmt: totalDebit.toFixed(2),
          CrAmt: totalCredit.toFixed(2),
          Balance: totalBalance.toFixed(2),
          PDays: '',
          isTotalRow: true
        }
      ];
    });

    return groupedWithTotals.flat();
  };

  return (
    <div className="p-3 mx-auto bg-gray-50 rounded-lg shadow-md">
      {showLoader && <Loader />}
      <div className="flex flex-col gap-4 mb-1">
        <h1 className="text-xl text-center font-bold ">{targetPage.FormName}</h1>
        <form onSubmit={handleSubmit} className="space-y-2">
          <div className="grid grid-cols-2 lg:grid-cols-6 space-x-2">
            <Dropdown
              options={[
                { value: '', label: 'Select Filter' },
                { value: 'Agentwise', label: 'Agentwise' },
                { value: 'Partywise', label: 'Partywise' },
              ]}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              label="Filter"
              isDisabled={false}
            />
            <Dropdown
              options={dropdown2Options}
              value={dropdown2}
              onChange={(e) => setDropdown2(e.target.value)}
              label="Agent/Party Name"
              isDisabled={false}
            />
          </div>
          <div className='grid justify-end'>
            <DynamicButton variant="primary" label="Submit" />
          </div>
        </form>
      </div>
      <Table columns={columns} data={rows} /> {/* Replace with actual data */}
    </div>
  );
};

export default ReceivableBills;
